<template>
    <div class="login-page">
        <div class="card">
            <div class="card-header">
                <div class="header-top">Scala</div>
                <div class="header-bottom">Director</div>
            </div>
            <div class="card-body">
                <form v-on:submit.prevent="submit()">
                    <div v-if="error">{{ error }}</div>
                    <input id="email" type="email" v-model="username"
                           class="form-control" placeholder="Email"
                           name="email" required autocomplete="email" autofocus>

                    <input id="password" type="password"
                           class="form-control" v-model="password"
                           placeholder="Password" name="password"
                           required autocomplete="current-password">

                    <div class="login-button-container">
                        <button class="login-button" type="submit">
                            Login
                        </button>
                    </div>

                    <div class="ribbon">
                        <img src="@/img/logo-black-rotated.png"/>
                    </div>
                </form>
            </div>
        </div>
        <div class="wall back"></div>
    </div>
</template>

<script>
export default {
  name: 'login',

  data() {
    return {
      username: null,
      password: null,
      error: '',
    }
  },

  methods: {

    submit() {

      this.error = null;

      if (this.username === '' || this.username === null) {
        this.error = "Email is required";
        return;
      }
      if (this.password === '' || this.password === null) {
        this.error = "Password is required";
        return;
      }

      this.$talker.login({username: this.username, password: this.password})
        .then(() => {
          let path = (this.$route.query.redirect) ? this.$route.query.redirect : '/';
          let query = (this.$route.query.ei) ? this.$route.query : {};
          query['redirect'] = undefined;
          this.$router.push({path, query})
        })
        .catch((err) => {
          if (err.message) {
            this.$notify.error(err.message);
          } else {
            this.$notify.error(err);
          }

        })
    },
  }

}
</script>

<style scoped lang="scss">


.login-page {
    font-size: 0.66em;
    height: 100%;
    color: #eee;
    font-weight: 700;
    background-color: #0F1C2B;
    display: flex;
    position: relative;
    z-index: 1;
}

.login-page::before {
    content: '';
    position: fixed;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url('~@/img/triangles.png'), url('~@/img/triangles.png'), url('~@/img/triangles-2.png'), url('~@/img/triangles-4.png'), url('~@/img/triangles-5.png');
    background-size: 0px, 0px, 500px, 500px, 500px;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat-y;
    background-position: 50% 20%, 50% 70%, 65% 21%, 35% 80%, 0 10%;
    pointer-events: none;
}

.login-page .justify-content-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: rgba(43, 46, 55, 0.98);
}

.login-page .card {
    z-index: 2;
    margin: auto;
    background: #dd4540;
    padding: 2em 4em;
    border-radius: 2.5em;
    border: 0.3em solid #eee;
    width: 400px;
    position: relative;
    height: 32em;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.7);
}

.login-page .invalid-feedback {
    text-align: center;
    margin-top: 0.2em;
}

.login-page .card-header {
    padding: 2em 0 2em;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1em;

    .header-top {
        font-size: 3em;
        line-height: 1em;
        margin-bottom: 0.2em;
    }

    .header-bottom {
        font-size: 4.5em;
        line-height: 1em;
    }
}

input {
    width: 100%;
    padding: 0.5em 0.2em;
    background: #dc615e;
    border: none;
    border-radius: 10px;
    font-size: 2.2em;
    box-sizing: border-box;
    margin-bottom: 0.3em;
    color: #eee;
    outline: none;
    font-weight: 300;
    text-align: center;
}

.login-page ::placeholder {
    color: #ddd;
}

.login-page .forgot-password {
    position: absolute;
    bottom: 1em;
    left: 6em;
    color: #eee;
    text-decoration: none;
}

.login-page .forgot-password:hover {
    text-decoration: underline;
}

.login-page .login-button {
    position: absolute;
    right: 3.1em;
    background: #fff;
    border: 1px solid rgba(180, 0, 0, 1);
    border-top: 1px solid rgba(180, 0, 0, 1);
    border-bottom: 3px solid rgba(180, 0, 0, 1);
    box-sizing: border-box;
    display: inline-block;
    color: #EE3235;
    padding: 1em 1.5em;
    border-radius: 4px;
    text-transform: uppercase;
    transition: background .2s ease-in-out;
    cursor: pointer;
    font-weight: 600;
    outline: none;
    margin-top: 0;
    font-size: 1.3em;
}

.login-button-container {
    margin-top: 2em;
}

.login-page .login-button:active {
    margin-top: 2px;
    border-bottom: 1px solid rgba(180, 0, 0, 1);
}

.login-page .ribbon {
    position: absolute;
    right: 100%;
    background: #fff;
    padding: 1.5em 0.9em 1.1em;
    border-radius: 10px 0 0 10px;
    top: 5em;
}

.login-page .ribbon img {
    height: 6em;
}

</style>